import React, { useState, useEffect, useContext } from "react";
import { query, orderBy, collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const Context = React.createContext();

export const LinesDataProvider = ({ children }) => {
    const [lines, setLines] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const linesRef = collection(firestore, "lines");
            await getDocs(query(linesRef, orderBy("dateCreated", "desc")))
                .then((querySnapshot) => {
                    const linesData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setLines(linesData);
                })
        };
        fetchData();
    }, [])

    return (
        <Context.Provider value={lines}>
            {children}
        </Context.Provider>
    )
}

export const useLines = () => useContext(Context);