export const getCharacterName = (characters, characterId) => {
    return characters.find(character => characterId === character.id).name;
}

export const getShowName = (shows, showId) => {
    return shows.find(show => showId === show.id).name;
}

export const getCharacterLines = (lines, characterId) => {
    return lines.filter(line => characterId === line.characterId);
}

export const getShowLines = (lines, showId) => {
    return lines.filter(line => showId === line.showId);
}