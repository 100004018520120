import { Button, Card, Divider, Input, Form, Modal, Space, Typography } from 'antd';
import { AppleFilled, FacebookFilled, GoogleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { auth } from "../firebase/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

const { Link, Text } = Typography;

function Aside({user}) {
    const [signUpForm] = Form.useForm();
    const [loginForm] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignUpButtonDisabled, setIsSignUpButtonDisabled] = useState(true);
    const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const logout = () => {
        auth.signOut().then(function () {
        }).catch(function (error) {
        });
    }

    const showLoginModal = () => {
        setIsLoginModalOpen(true);
    };

    const resetSignUpForm = () => {
        signUpForm.resetFields();
        setIsSignUpButtonDisabled(true);
    };

    const resetLoginForm = () => {
        loginForm.resetFields();
        setIsLoginButtonDisabled(true);
    };

    const handleCancel = () => {
        resetSignUpForm();
        setIsModalOpen(false);
    };

    const handleLoginCancel = () => {
        resetLoginForm();
        setIsLoginModalOpen(false);
    };

    const onSignUpFieldsChange = () => {
        const hasErrors = signUpForm.getFieldValue("email") === undefined
            || signUpForm.getFieldValue("password") === undefined
            || signUpForm.getFieldValue("confirm") === undefined
            || signUpForm.getFieldsError().some(({ errors }) => errors.length);
        setIsSignUpButtonDisabled(hasErrors);
    };

    const onSignUpFinish = (values) => {
        createUserWithEmailAndPassword(auth, values["email"], values["password"])
            .then(_ => {
                resetSignUpForm();
                setIsModalOpen(false);
            })
            .catch((error) => {
                console.log('Failure:', error);
            });
    };

    const onSignUpFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onLoginFieldsChange = () => {
        const hasErrors = loginForm.getFieldValue("email") === undefined
            || loginForm.getFieldValue("password") === undefined
            || loginForm.getFieldsError().some(({ errors }) => errors.length);
        console.log(loginForm.getFieldValue("email"));
        console.log(loginForm.getFieldValue("password"));
        console.log(hasErrors);
        setIsLoginButtonDisabled(hasErrors);
    };

    const onLoginFinish = (values) => {
        console.log('Success:', values);
        signInWithEmailAndPassword(auth, values["email"], values["password"])
            .then(_ => {
                console.log(user);
                resetLoginForm();
                setIsLoginModalOpen(false);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    };

    const onLoginFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <aside>
            {/* <Card style={{ width: 300, textAlign: 'center' }}>
                {user ? (
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Text>User: {user.email}</Text>
                        <Button block onClick={logout}>Log out</Button>
                    </Space>
                ) : (
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Text strong>Login to submit your favorite lines!</Text>
                        <Button type="primary" block onClick={showLoginModal}>Login</Button>
                        <Text type="secondary">Don't have an account? <Link onClick={showModal}>Sign up</Link></Text>
                    </Space>
                )}
            </Card>
            <Modal title="Sign up" width="380px" open={isModalOpen} onCancel={handleCancel} footer={null}>
                <Space direction="vertical" size="middle" style={{ width: '100%', paddingTop: '24px' }}>
                    <Button block disabled icon={<GoogleOutlined />}>Sign up with Google</Button>
                    <Button block disabled icon={<FacebookFilled />}>Sign up with Facebook</Button>
                    <Button block disabled icon={<AppleFilled />}>Sign up with Apple</Button>
                </Space>
                <Divider plain>or</Divider>
                <Form
                    name="signUpForm"
                    layout="vertical"
                    onFieldsChange={onSignUpFieldsChange}
                    onFinish={onSignUpFinish}
                    onFinishFailed={onSignUpFinishFailed}
                    form={signUpForm}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        validateTrigger="onBlur"
                        rules={[
                            { required: true, message: 'Please enter an email' },
                            { type: "email", message: 'Please enter a valid email' },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                        label="Create password"
                        name="password"
                        hasFeedback
                        validateTrigger="onBlur"
                        rules={[
                            { required: true, message: 'Please enter a password' },
                            { min: 8, message: 'Your password must have a minimum of 8 characters' }
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            { min: 8, message: 'Your password must have a minimum of 8 characters' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <br />
                    <Form.Item>
                        <Button type="primary" block size="middle" htmlType="submit"
                            disabled={isSignUpButtonDisabled}>
                            Create my account
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Login" width="380px"
                open={isLoginModalOpen} onCancel={handleLoginCancel} footer={null}
                bodyStyle={{ paddingTop: '24px' }}>
                <Form
                    name="loginForm"
                    layout="vertical"
                    onFieldsChange={onLoginFieldsChange}
                    onFinish={onLoginFinish}
                    onFinishFailed={onLoginFinishFailed}
                    form={loginForm}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        validateTrigger="onBlur"
                        rules={[
                            { required: true, message: 'Please enter an email' },
                            { type: "email", message: 'Please enter a valid email' },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: 'Please enter a password' }
                        ]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>

                    <br />
                    <Form.Item>
                        <Button type="primary" block size="middle" htmlType="submit"
                            disabled={isLoginButtonDisabled}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </Modal> */}
        </aside>
    )
}

export default Aside;