import { Typography } from 'antd';

const { Text } = Typography;

function Footer() {
    return (
        <footer>
            <Text type="secondary">Made with love 
            and for fun by <a href="https://sylvie.dev" target="_blank" rel="noreferrer">
                Sylvie Donatien</a></Text>
        </footer>
    )
}

export default Footer;