import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Character, Home, NotFound, Show } from "./pages";
import { Header, Footer, Nav, Aside } from "./components";
import { auth } from "./firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);

  useEffect(()=>{
    onAuthStateChanged(auth, (user) => setUser(user));
  }, []);

  return (
    <div className="flex-layout">
      <div className="flex-left">
        <Header />
        <Nav />
      </div>
      <div className="flex-center">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shows/:showId" element={<Show />} />
            <Route path="/characters/:characterId" element={<Character />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </div>
      <div className="flex-right">
        <Aside user={user}/>
        <Footer />
      </div>
    </div>
  );
}
export default App;