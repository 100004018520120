import React, { useState, useEffect, useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const Context = React.createContext();

export const CharactersDataProvider = ({ children }) => {
    const [characters, setCharacters] = useState();

    useEffect(() => {
        const fetchData = async () => {
            await getDocs(collection(firestore, "characters"))
                .then((querySnapshot) => {
                    const charactersData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setCharacters(charactersData);
                })
        };
        fetchData();
    }, [])
    
    return (
        <Context.Provider value={characters}>
            {children}
        </Context.Provider>
    )
}

export const useCharacters = () => useContext(Context);