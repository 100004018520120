import React, { useState, useEffect, useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const Context = React.createContext();

export const ShowsDataProvider = ({ children }) => {
    const [shows, setShows] = useState();

    useEffect(() => {
        const fetchData = async () => {
            await getDocs(collection(firestore, "shows"))
                .then((querySnapshot) => {
                    const showsData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setShows(showsData);
                })
        };
        fetchData();
    }, [])

    return (
        <Context.Provider value={shows}>
            {children}
        </Context.Provider>
    )
}

export const useShows = () => useContext(Context);