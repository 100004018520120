import { Typography } from 'antd';
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

function Header() {
    return (
        <header>
            <Title>
                <Link to="/">
                    Lines
                </Link>
            </Title>
            <Text type="secondary">An anthology of the best TV show and movie lines</Text>
        </header>
    )
}

export default Header;