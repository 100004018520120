import React from "react";
import { Link } from "react-router-dom";
import { List, Typography, Skeleton } from 'antd';
import { toHowLongAgoString } from "../utils/date";
import usePrefersReducedMotion from "../hooks/usePrefersReducedMotion";
import { useCharacters, useLines, useShows } from "../data_providers";
import { getCharacterName, getShowName } from "../utils/data";

const { Text } = Typography;

function Lines() {
  const active = !usePrefersReducedMotion();

  const shows = useShows();
  const characters = useCharacters();
  const lines = useLines();

  const loading = !(shows && characters && lines);

  return (
    <>
      <Skeleton loading={loading} active={active} style={{padding: '24px'}}>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={lines}
          renderItem={(item) => (
            <article>
              <List.Item
                key={item.id}
              >
                <List.Item.Meta
                  title={<Text strong><Link
                    to={"/characters/" + item.characterId}>{getCharacterName(characters, item.characterId)}</Link>@<Link
                      to={"/shows/" + item.showId}>{getShowName(shows, item.showId)}</Link></Text>
                  }
                />
                {item.line}
                <br />
                <br />
                <Text type="secondary">
                  Added {toHowLongAgoString(item.dateCreated.toDate())}
                </Text>
              </List.Item>
            </article>
          )}
        />
      </Skeleton>
    </>
  );
}

export default Lines;
