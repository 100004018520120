import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, List, Typography, Skeleton } from 'antd';
import { useCharacters, useLines, useShows } from '../data_providers';
import { getCharacterName, getShowName, getCharacterLines } from '../utils/data';
import usePrefersReducedMotion from "../hooks/usePrefersReducedMotion";
import { toHowLongAgoString } from "../utils/date";

const { Text } = Typography;

function Character() {
    const { characterId } = useParams();
    const lines = useLines();
    const shows = useShows();
    const characters = useCharacters();
    const characterLines = getCharacterLines(lines, characterId);
    const active = !usePrefersReducedMotion();
    const navigate = useNavigate();
    const clickHandler = () => {
        navigate(-1);
    }

    const loading = !(characters && lines && characterLines && shows);

    return (
        <>
            <Button type="secondary" shape="circle" icon={<ArrowLeftOutlined />} onClick={clickHandler} aria-label="Go back"></Button>
            <Skeleton loading={loading} active={active}>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={characterLines}
                    renderItem={(item) => (
                        <article>
                            <List.Item
                                key={item.id}
                            >
                                <List.Item.Meta
                                    title={<Text strong>{getCharacterName(characters, item.characterId)}@<Link
                                        to={"/shows/" + item.showId}>{getShowName(shows, item.showId)}</Link></Text>
                                    }
                                />
                                {item.line}
                                <br />
                                <br />
                                <Text type="secondary">
                                    Added {toHowLongAgoString(item.dateCreated.toDate())}
                                </Text>
                            </List.Item>
                        </article>
                    )}
                />
            </Skeleton>
        </>
    )
}

export default Character;