const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

const toHowLongAgoString = (date) => {
    var today = new Date();

    if (date.toDateString() === today.toDateString()) {
      const diffInMilliseconds = Math.abs(today - date);
      const diffInHours = Math.trunc(diffInMilliseconds/1000/60/60);
      const diffInMinutes = Math.trunc(diffInMilliseconds/1000/60);
      const diffInSeconds = Math.trunc(diffInMilliseconds/1000);

      if (diffInSeconds < 60) {
        return `${diffInSeconds}s ago`;
      } else if (diffInMinutes < 60) {
        return `${diffInMinutes}m ago`;
      } else {
        return `${diffInHours}h ago`;
      }
    } else {
      return `on ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    }
  }

export { toHowLongAgoString };